import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";

import StyledCheckbox from "components/StyledCheckBox";

const Button = styled.div`
  cursor: pointer;
  background: ${(props) => (props.selected ? props.color : props.theme.background)};
  border-radius: 8px;
  border: 3px solid ${(props) => (props.color)};
  color: ${(props) => (props.selected ? props.theme.background : props.theme.text)};
  display: flex;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  padding: 0.3em 0.6em 0.3em 0.25em;
  transition: 0.2s;
  margin: 5px;
`;

const Text = styled.div`
  margin-left: 5px;
`;

const Checkbox = styled(StyledCheckbox)`
  background: ${(props) => (props.theme.background)};
  box-shadow: 0 0 0 3px ${(props) => (props.color)};
  .styledCheckboxIcon {
    stroke: ${(props) => (props.color)};
  }
`;

const Filter = ({ color, value, selected, onFilterChange, ...props }) => {
  const handleChange = () => onFilterChange(value, !selected);

  return (
    <Button color={color} value={value} selected={selected} onClick={handleChange} {...props}>
      <Checkbox type="small" color={color} selected={selected} />
      <Text className="filterText">{props.children.toUpperCase()}</Text>
    </Button>
  );
};

Filter.defaultProps = {
  selected: false,
};

Filter.propTypes = {
  color          : PropTypes.string.isRequired,
  onFilterChange : PropTypes.func.isRequired,
  selected       : PropTypes.bool,
  value          : PropTypes.string.isRequired,
  children       : PropTypes.string.isRequired,
};

export default Filter;
