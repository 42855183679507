import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Checkbox from "components/Checkbox";

export const Item = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-basis: calc(100% / 5 - 20px);
  margin: 10px;
  padding: 3px;
  border-radius: 5px;
  box-shadow: 0 0 0 3px ${(props) => (props.selected ? props.theme.primary : props.theme.photoItemBorder)};

  @media (max-width: 1600px) {
    flex-basis: calc(100% / 4 - 20px);
  }
  @media (max-width: 1280px) {
    flex-basis: calc(100% / 3 - 20px);
  }
  @media (max-width: 960px) {
    flex-basis: calc(100% / 2 - 20px);
  }
  @media (max-width: 768px) {
    flex-basis: calc(100% - 20px);
  }
`;

export const PhotoSection = styled.div`
  position: relative;
  font-size: 0;
  padding-top: calc(1 / 1.5 * 100%);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
  background-color: ${(props) => props.theme.photoItemBorder};
`;

export const BottomSection = styled.div`
  display: flex;
  min-height: 60px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5px 0;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Tags = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const Img = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  background-color: ${(props) => props.theme.photoItemBorder};
  transform: translateX(-50%);
`;

export const Tag = styled.div`
  padding: 3px 5px;
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 5px;
  cursor: default;
  background-color: ${(props) => (props.bought ? props.theme.tag.bought : props.theme.tag.developed)}
`;

export const Date = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 700;
  text-align: right;
`;

export const ID = styled.span`
  display: block;
  font-size: 12px;
  font-weight: 600;
  text-align: right;
  color: #aaa;
`;

export const StyledCheckbox = styled(Checkbox)`
  position: absolute;
  bottom: 5px;
  left: 5px;
`;

function PhotoItem({ photo, selected, onSelectionChange, ...props }) {
  const { t } = useTranslation();

  return (
    <Item {...props} selected={selected}>
      <PhotoSection>
        <Img src={photo.url} alt="" />
      </PhotoSection>
      <BottomSection>
        <Tags>
          {photo.status === "PREVIEW" && <Tag developed>{t("components.photoItem.status.developed")}</Tag>}
          {photo.status === "BOUGHT" && <Tag bought>{t("components.photoItem.status.bought")}</Tag>}
        </Tags>
        <Info>
          <Date>{photo.date}</Date>
          <ID>{`#${photo.id}`}</ID>
        </Info>
        <StyledCheckbox photoId={photo.id} selected={selected} onSelectionChange={onSelectionChange}  />
      </BottomSection>
    </Item>
  );
}

PhotoItem.propTypes = {
  photo             : PropTypes.object.isRequired,
  selected          : PropTypes.bool.isRequired,
  onSelectionChange : PropTypes.func.isRequired,
};

export const isIdentical = (prev, next) => prev.selected === next.selected && prev.photo.status === next.photo.status;

const MemoPhotoItem = React.memo(PhotoItem, isIdentical);

export default MemoPhotoItem;
