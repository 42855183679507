import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import H2 from "components/H2";
import P from "components/P";

import emptyImg from "images/photolist-empty.png";

export const EmptyListWrapper = styled.div`
  margin-top: 25px;
`;

export const DownloadLink = styled(Button)`
  position: relative;
`;

export const Link = styled.a`
  position: relative;
  display: inline-block;
`;

export const EmptyMessage = styled(P)`
  max-width: 70%;
  margin-bottom: 20px;
`;

export const EmptyImg = styled.img`
  position: absolute;
  bottom: 0;
  right: 5vw;
  max-width: 50%;
  max-height: 65%;
`;

export default function PhotoListEmpty(props) {
  const { t } = useTranslation();

  return (
    <EmptyListWrapper {...props}>
      <H2>{t("components.photoListEmpty.title")}</H2>
      <EmptyMessage>{t("components.photoListEmpty.message")}</EmptyMessage>
      <DownloadLink href={process.env.REACT_APP_EXPOSIO_APP_WEBSITE} color="primary" as={Link}>
        {t("components.photoListEmpty.download")}
      </DownloadLink>
      <EmptyImg src={emptyImg} alt="" />
    </EmptyListWrapper>
  );
}
