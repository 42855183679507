import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import logo from "images/logo-exposio.png";

export const HeaderWrapper = styled.header`
  display: flex;
  height: 60px;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 5%;
  padding-left: 5%;
  align-items: center;
  background: ${(props) => props.theme.headerBackground};
`;

export const StyledLogo = styled.img`
  width: 160px;
  height: auto;
`;

const LoadingHeader = (props) => (
  <HeaderWrapper {...props}>
    <StyledLogo src={logo} className="logo" {...props} />
    {props.children}
  </HeaderWrapper>
);

LoadingHeader.defaultProps = {
  children: null,
};

LoadingHeader.propTypes = {
  children: PropTypes.node,
};

export default LoadingHeader;
