import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";

const rotate360 = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  animation: ${rotate360} 0.5s linear infinite;
  transform: translateX(-50%) translateY(-50%) translateZ(0);
  border-top: 2px solid ${(props) => props.theme.background};
  border-right: 2px solid ${(props) => props.theme.background};
  border-bottom: 2px solid ${(props) => props.theme.background};
  border-left: 4px solid ${(props) => props.theme.text};
  background: transparent;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
`;

export default Spinner;

Spinner.propTypes = {
  size: PropTypes.number,
};

Spinner.defaultProps = {
  size: 32,
};
