import React from "react";
import styled from "styled-components";

export const Item = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-basis: calc(100% / 5 - 20px);
  margin: 10px;
  padding: 3px;
  border-radius: 5px;
  box-shadow: 0 0 0 3px ${(props) => props.theme.photoItemBorder};

  @media (max-width: 1600px) {
    flex-basis: calc(100% / 4 - 20px);
  }
  @media (max-width: 1280px) {
    flex-basis: calc(100% / 3 - 20px);
  }
  @media (max-width: 960px) {
    flex-basis: calc(100% / 2 - 20px);
  }
  @media (max-width: 768px) {
    flex-basis: calc(100% - 20px);
  }
`;

export const BottomSection = styled.div`
  display: flex;
  min-height: 60px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5px 0;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Tags = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const Img = styled.div`
  width: 100%;
  padding-top: calc(1 / 1.5 * 100%);
  background-color: ${(props) => props.theme.photoItemBorder};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const Tag = styled.div`
  width: 72px;
  height: 18px;
  border-radius: 5px;
  cursor: default;
  background-color: ${(props) => props.theme.photoItemBorder};
`;

export const Date = styled.span`
  display: block;
  width: 85px;
  height: 18px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.photoItemBorder};
`;

export const ID = styled.span`
  display: block;
  width: 70px;
  height: 18px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.photoItemBorder};
  margin-top: 3px;
  margin-left: auto;
`;

export default function LoadingItem(props) {
  return (
    <Item {...props}>
      <Img />
      <BottomSection>
        <Tags>
          <Tag />
        </Tags>
        <Info>
          <Date />
          <ID />
        </Info>
      </BottomSection>
    </Item>
  );
}
