import React from "react";
import { useUserContext } from "contexts";

import ProfileForm from "components/ProfileForm";

export default function ProfileFormContainer() {
  const { user } = useUserContext();

  return (<ProfileForm user={user} />);
}
