import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useHistory } from "react-router-dom";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import qs from "query-string";

import { useQuery } from "containers/PhotoList/hooks";
import PageScroller from "containers/PageScroller";
import PhotoList from "components/PhotoList";
import PhotoListFilters from "components/PhotoListFilters";
import PhotoListLoadMore from "components/PhotoListLoadMore";
import PhotoListSelectionProvider from "containers/PhotoListSelectionProvider";
import PhotoListActions from "components/PhotoListActions/PhotoListActions";
import PhotoListEmpty from "components/PhotoListEmpty";
import { usePhotoListSelectionContext } from "contexts";

export const PhotoListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2.5em 0 2em;
  width: 100%;
  flex-flow: row wrap;

  :before {
    content: "";
    display: block;
    position: relative;
    top: -.2em;
    width: 100%;
    height: .1em;
    min-height: 2px;
    background: #000000;
  }
`;

export const withSelectionContext = (Component) => function HOCSelectionContext(props) {
  const { isSelected, updateSelected } = usePhotoListSelectionContext();

  return (<Component isSelected={isSelected} updateSelected={updateSelected} {...props} />);
};

export const PhotoListWithSelectionContext = withSelectionContext(PhotoList);

const PhotoListContainer = ({
  pages,
  size,
  nextPage,
  isLoadingMore,
  nbPerPage,
}) => {
  const history = useHistory();
  const query   = useQuery();
  const isEmpty = [].concat(...pages).length === 0;

  // Load more when reaching 500 px from the bottom
  useBottomScrollListener(nextPage, 500, 10);

  const photoListProps = {
    pages,
    nbPerPage,
    isLoadingMore,
  };

  const updateFilters = (status) => {
    const queryString = qs.stringify({ status });

    history.push({
      search: queryString,
    });
  };

  const selected = query.getAll("status") || [];

  return isEmpty
    ? (<PhotoListEmpty />)
    : (
      <PhotoListSelectionProvider>
        <PageScroller nbPage={size}>
          <PhotoListHeader>
            <PhotoListFilters selected={selected} updateFilters={updateFilters} />
            <PhotoListActions />
          </PhotoListHeader>

          <PhotoListWithSelectionContext {...photoListProps} />
          <PhotoListLoadMore onClick={nextPage} />
        </PageScroller>
      </PhotoListSelectionProvider>
    );
};

PhotoListContainer.propTypes = {
  pages         : PropTypes.array.isRequired,
  size          : PropTypes.number.isRequired,
  nextPage      : PropTypes.func.isRequired,
  isLoadingMore : PropTypes.bool.isRequired,
  nbPerPage     : PropTypes.number.isRequired,
};

export default PhotoListContainer;
