import styled from "styled-components";

export const LayoutLeftMenu = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
`;

export const Menu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 20vw;
  height: 100%;
  padding-top: 60px;
  color: ${(props) => props.theme.menuText};
  background-color: ${(props) => props.theme.menuBackground};
`;

export const Content = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: calc(3vw + 60px) 5vw 3vw;
  margin-left: 20vw;
`;
