import React from "react";
import { useTranslation } from "react-i18next";

import { LayoutLeftMenu, Content, Menu } from "layouts/LeftMenu";
import ProfileForm from "containers/ProfileForm";
import H1 from "components/H1";
import SideMenu from "components/SideMenu";

function Profile() {
  const { t } = useTranslation();

  return (
    <LayoutLeftMenu>
      <Menu>
        <SideMenu />
      </Menu>
      <Content>
        <H1>{t("views.profile.title")}</H1>
        <ProfileForm />
      </Content>
    </LayoutLeftMenu>
  );
}

export default Profile;
