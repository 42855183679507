import React from "react";
import styled from "styled-components";

import LoadingHeader from "components/LoadingHeader";
import Spinner from "components/Spinner";

export const CenteredSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
`;

function Loading() {
  return (
    <>
      <LoadingHeader />
      <CenteredSpinner />
    </>
  );
}

export default Loading;
