import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import PrivateRoute from "containers/PrivateRoute";
import UserProvider from "containers/UserProvider";
import Header from "components/Header";
import Login from "views/Login";
import Photos from "views/Photos";
import Profile from "views/Profile";

export default function App() {
  return (
    <Router>
      <UserProvider>
        <Header />
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <PrivateRoute path="/photos">
            <Photos />
          </PrivateRoute>
          <PrivateRoute path="/profile">
            <Profile />
          </PrivateRoute>
        </Switch>
      </UserProvider>
    </Router>
  );
}
