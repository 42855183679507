import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const Icon = styled.svg`
  fill: none;
  stroke: ${(props) => props.theme.background};
  stroke-width: 2px;
`;

const types = {
  default : { size: "20px" },
  small   : { size: "15px" },
};

export const Checkbox = styled.label`
  display: inline-block;
  width: ${(props) => (types[props.type].size)};
  height: ${(props) => (types[props.type].size)};
  margin: 3px;
  background: ${(props) => (props.selected ? props.theme.primary : props.theme.background)};
  box-shadow: 0 0 0 3px ${(props) => (props.theme.primary)};
  border-radius: 3px;
  transition: all 150ms;
  cursor: pointer;

  ${Icon} {
    stroke: ${(props) => (props.theme.background)};
    visibility: ${(props) => (props.selected ? "visible" : "hidden")}
  }
`;

const StyledCheckbox = ({ selected, type, ...props }) => (
  <Checkbox type={type} selected={selected} {...props}>
    <Icon className="styledCheckboxIcon" viewBox="0 0 24 24">
      <polyline points="20 6 9 17 4 12" strokeWidth="3" />
    </Icon>
  </Checkbox>
);

StyledCheckbox.defaultProps = {
  selected : false,
  type     : "default",
};

StyledCheckbox.propTypes = {
  selected : PropTypes.bool,
  type     : PropTypes.string,
};

export default StyledCheckbox;
