import React  from "react";
import styled  from "styled-components";
import { useUserContext } from "contexts";

import Credits from "components/Credits";
import NavLink from "components/NavLink";
import UserInfo from "containers/UserInfo";
import { useTranslation } from "react-i18next";

const Row = styled.div`
  display: flex;
  align-items: center;
`;

export default function NavbarContainer() {
  const { user }          = useUserContext();
  const { t }             = useTranslation();
  const exposioAppWebsite = process.env.REACT_APP_EXPOSIO_APP_WEBSITE;

  // User is logged in, display credits and user info
  if (user) {
    return (
      <Row>
        <Credits total={parseInt(user.credits, 10)} />
        <UserInfo />
      </Row>
    );
  }

  // User is not logged in, display a link to go back to the main website
  return <NavLink href={exposioAppWebsite}>{t("components.header.return")}</NavLink>;
}
