import React from "react";
import styled from "styled-components";

import DownloadButton from "containers/DownloadButton";
import BuyButton from "containers/BuyButton";
import DeleteButton from "containers/DeleteButton";
import UnselectButton from "containers/UnselectButton";

export const Row = styled.div`
  display: flex;

  & > * {
    margin: 5px;

    &:last-child {
      margin-right: 0px;
    }
  }
`;

export default function PhotoListActions() {
  return (
    <Row>
      <UnselectButton />
      <DeleteButton />
      <DownloadButton />
      <BuyButton />
    </Row>
  );
}
