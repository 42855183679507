import React from "react";
import { useTranslation } from "react-i18next";

import { LayoutLeftMenu, Content, Menu } from "layouts/LeftMenu";
import H1 from "components/H1";
import SideMenu from "components/SideMenu";
import PhotoList from "containers/PhotoList";
import PhotoListProvider from "containers/PhotoListProvider";
import { PhotoListContext } from "contexts";

export const PhotoListWithContext = (context) => <PhotoList {...context} />;

function Photos() {
  const { t } = useTranslation();

  return (
    <LayoutLeftMenu>
      <Menu>
        <SideMenu />
      </Menu>
      <Content>
        <H1>{t("views.photos.title")}</H1>
        <PhotoListProvider>
          <PhotoListContext.Consumer>
            {PhotoListWithContext}
          </PhotoListContext.Consumer>
        </PhotoListProvider>
      </Content>
    </LayoutLeftMenu>
  );
}

export default Photos;
