import React from "react";
import styled from "styled-components";
import { useUserContext } from "contexts";
import { NavLink } from "react-router-dom";

import logo from "images/logo-exposio.png";

const StyledLogo = styled.img`
  width: 160px;
  height: auto;
`;

const Link = styled(NavLink)`
  font-size: 0;
`;

const Logo = (props) => {
  const { user } = useUserContext();

  return (
    <Link data-testid="link" to={typeof user === "undefined" ? "/" : "/photos"}>
      <StyledLogo src={logo} className="logo" {...props} />
    </Link>
  );
};

export default Logo;
