import React, { useContext } from "react";

// UserContext
export const UserContext = React.createContext({});
export const useUserContext = () => useContext(UserContext);
export default UserContext;

// PhotoListSelectionContext
export const PhotoListSelectionContext = React.createContext({});
export const usePhotoListSelectionContext = () => useContext(PhotoListSelectionContext);

// PhotosContext
export const PhotoListContext = React.createContext({});
export const usePhotoListContext = () => useContext(PhotoListContext);
