import styled from "styled-components";

export const colors = (theme) => ({
  default: {
    border         : theme.primary,
    borderDisabled : theme.text,
    background     : theme.background,
    color          : theme.text,
  },
  primary: {
    border         : theme.primary,
    borderDisabled : theme.primary,
    background     : theme.primary,
    color          : theme.background,
  },
});

const Button = styled.button`
  cursor: pointer;
  background: ${(props) => colors(props.theme)[props.color].background};
  border-radius: 8px;
  border: 3px solid ${(props) => colors(props.theme)[props.color].border};
  color: ${(props) => colors(props.theme)[props.color].color};
  display: flex;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  padding: 0.65em 0.8em;
  transition: 0.2s;
  text-transform: uppercase;
  outline: none;

  &[disabled] {
    opacity: 0.3;
    cursor: default;
    border-color: ${(props) => colors(props.theme)[props.color].borderDisabled};
  }
`;

Button.defaultProps = {
  color: "default",
};

export default Button;
