import React, { useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { cache } from "swr";

import { UserContext } from "contexts";
import { getToken } from "utils/api";
import { useCurrentUser } from "./hooks";

/**
 * UserProvider component allows children components
 * to access the connected user informations.
 *
 * There is two part to authenticate a user:
 *
 * 1. We get a valid access_token from the SSO
 *    with the user credentials and we store it
 *    in localStorage to be able to reuse it after the
 *    page reloads.
 *
 * 2. With the access_token from the localStorage, we fetch
 *    the user informations (`getCurrentUser`) from the API
 *    and we pass it to the context with utility function to
 *    login or logout the user.
 *
 * To access the current user simply call:
 * const { user } = useUserContext();
 */
export default function UserProvider({ children }) {
  const [accessToken, setToken] = useState(localStorage.getItem("accessToken"));

  const logout = useCallback(() => {
    const email = localStorage.getItem("email");
    cache.clear();
    localStorage.clear();
    localStorage.setItem("email", email);
    setToken(null);
  }, [setToken]);

  const login = useCallback(async (email, password) => {
    cache.clear();
    localStorage.clear();

    const token = await getToken(email, password);

    localStorage.setItem("email", email);
    localStorage.setItem("accessToken", token.access_token);
    localStorage.setItem("refreshToken", token.refresh_token);
    setToken(token.access_token);
  }, []);

  // Get the current user
  const { data:user, mutate } = useCurrentUser(accessToken, logout);

  const userState = useMemo(() => ({
    user,
    logout,
    login,
    clearUserCache: mutate,
  }), [user, logout, login, mutate]);

  return (
    <UserContext.Provider value={userState}>
      {children}
    </UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
