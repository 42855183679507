import React from "react";
import LoginForm from "components/LoginForm";
import { useUserContext } from "contexts";

export default function LoginFormContainer() {
  const { login } = useUserContext();

  // Wrap onLogin to inject effect hooks
  const onFormSubmit = ({ email, password }) => login(email, password);

  return (<LoginForm email={localStorage.getItem("email")} onLogin={onFormSubmit} />);
}
