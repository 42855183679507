import PropTypes from "prop-types";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import debounce from "utils/debounce";

export const navigateToCurrentPage = (hashFragment) => {
  const element = document.getElementById(`${hashFragment}-0`);

  if (window.scrollY < 50 && hashFragment !== "page-1" && element !== null) {
    element.scrollIntoView();

    return true;
  }

  return false;
};

export const handleScroll = (history, nbPage, getElement) => () => {
  const scrollNode = getElement();
  const position   = window.scrollY + scrollNode.offsetTop;
  const height     = scrollNode.scrollHeight;

  const currentPage = Math.ceil(position / height * nbPage);
  const urlPage     = history.location.hash.replace("#page-", "") * 1;

  if (currentPage !== urlPage) {
    history.push(`${history.location.search}#page-${currentPage}`);
  }
};

export const getPhotoListElement = () => document.getElementById("photolist");

export default function PageScroller({ children, nbPage }) {
  const history = useHistory();

  // Listening to scroll events to update the current page in the URL
  useEffect(() => {
    const callback        = handleScroll(history, nbPage, getPhotoListElement);
    const debouncedScroll = debounce(callback, 300);

    window.addEventListener("scroll", debouncedScroll);

    // Cleanup, remove listener when PageScroller is unmounted
    return () => window.removeEventListener("scroll", debouncedScroll);
  });

  // Navigate to the page from the url
  navigateToCurrentPage(history.location.hash.replace("#", ""));

  return children;
}

PageScroller.propTypes = {
  children : PropTypes.any.isRequired,
  nbPage   : PropTypes.number.isRequired,
};
