import styled from "styled-components";

const H1 = styled.h1`
  position: relative;
  font-size: 2em;
  font-family: "Montserrat";
  line-height: 1.4;
  margin-top: .2em;
  margin-bottom: 0.25em;
  color: ${(props) => props.theme.text};

  :before {
    content: "";
    display: block;
    position: relative;
    top: -.2em;
    width: 100%;
    height: .1em;
    min-height: 2px;
    background: ${(props) => props.theme.text};
  }
`;

export default H1;
