import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import H2 from "components/H2";
import Button from "components/Button";
import P from "components/P";
import Modal from "components/Modal";

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const AlertButton = styled(Button)`
  margin-right: 10px;
`;

const ConfirmAlert = ({
  title, message, confirmButtonText, cancelButtonText, onConfirm, onCancel, ...props
}) => (
  <Modal onClick={onCancel} {...props}>
    <H2>{title}</H2>
    <P>{message}</P>
    <Buttons>
      <AlertButton onClick={onCancel}>
        {cancelButtonText}
      </AlertButton>
      <AlertButton color="primary" onClick={onConfirm}>
        {confirmButtonText}
      </AlertButton>
    </Buttons>
  </Modal>
);

ConfirmAlert.propTypes = {
  title             : PropTypes.string.isRequired,
  message           : PropTypes.string.isRequired,
  confirmButtonText : PropTypes.string.isRequired,
  cancelButtonText  : PropTypes.string.isRequired,
  onConfirm         : PropTypes.func.isRequired,
  onCancel          : PropTypes.func.isRequired,
};

export default ConfirmAlert;
