import "i18n";
import "normalize.css";
import "index.css";

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import WebFont from "webfontloader";
import { ThemeProvider } from "styled-components";
import { syncWithLocalStorage } from "swr-sync-storage";
import * as serviceWorker from "serviceWorker";

import App from "containers/App";

import Loading from "views/Loading";
import Exposio from "themes/exposio";

syncWithLocalStorage();

WebFont.load({
  google: {
    families: ["Open+Sans:400,600,700", "Montserrat", "sans-serif"],
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={Exposio}>
      <Suspense fallback={<Loading />}>
        <App />
      </Suspense>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
