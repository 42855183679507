import React, { useReducer, useCallback } from "react";
import PropTypes from "prop-types";

import { PhotoListSelectionContext } from "contexts";

const ACTION_REMOVE = "REMOVE";
const ACTION_ADD    = "ADD";
const ACTION_CLEAR  = "CLEAR";

export const selectionReducer = (state, action) => {
  const { type, id } = action;

  switch (type) {
  case ACTION_REMOVE:
    return state.filter((existingId) => existingId !== id);

  case ACTION_ADD:
    return [...state, id];

  case ACTION_CLEAR:
    return [];

  default:
    return state;
  }
};

export default function PhotoListSelectionProvider({ children }) {
  const [selected, dispatch] = useReducer(selectionReducer, []);

  const addToSelection      = useCallback((id) => dispatch({ type: ACTION_ADD, id }), []);
  const removeFromSelection = useCallback((id) => dispatch({ type: ACTION_REMOVE, id }), []);

  const isSelected = useCallback((id) => selected.includes(id), [selected]);

  const updateSelected = useCallback((id) => {
    if (isSelected(id)) {
      removeFromSelection(id);
    } else {
      addToSelection(id);
    }
  }, [addToSelection, removeFromSelection, isSelected]);

  const clear = useCallback(() => dispatch({ type: ACTION_CLEAR }), []);

  const context = {
    nbSelected: selected.length,
    isSelected,
    updateSelected,
    selected,
    clear,
  };

  return (
    <PhotoListSelectionContext.Provider value={context}>
      {children}
    </PhotoListSelectionContext.Provider>
  );
}

PhotoListSelectionProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
