import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useUserContext } from "contexts";

export default function PrivateRoute({ children, ...routeProps }) {
  const { user } = useUserContext();
  const history  = useHistory();

  // Authenticated if there is a username
  // If there is a username it means that a resource
  // has been fetched from the SSO server with
  // a valid token.
  const authenticated = user?.username !== "" && typeof user?.username !== "undefined";

  return (
    <Route
      {...routeProps}
      render={() => (authenticated === true
        ? (children)
        : <Redirect to={{ pathname: "/", state: { from: history.location.pathname } }} />)}
    />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.any.isRequired,
};
