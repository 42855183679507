import { getPhotos } from "utils/api";
import { useSWRInfinite } from "swr";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const useQuery = () => new URLSearchParams(useLocation().search);

export const formatDate = (msTimestamp, language) => new Date(msTimestamp * 1000).toLocaleString(language, {
  year  : "numeric",
  month : "long",
  day   : "numeric",
});

export const mapAPIResponseToPhotoItem = (photo, language) => ({
  id           : photo.id,
  url          : photo.urls?.mobile,
  url_original : photo.urls?.original,
  date         : formatDate(photo.timestamp_creation, language),
  status       : photo.process_status,
  cost         : parseInt(photo.cost, 10),
});

export const usePhotos = (PAGE_SIZE, statuses = []) => {
  const { i18n } = useTranslation();

  const photoFetcher = async (pageNumber, pageSize) => {
    const apiPhotos = await getPhotos(pageNumber, pageSize, statuses);

    return apiPhotos.map((photo) => mapAPIResponseToPhotoItem(photo, i18n.language));
  };

  const getKey = (index) => {
    if (localStorage.getItem("accessToken")) {
      const statusString = statuses.join(",");

      return [index + 1, PAGE_SIZE, statusString, "getPhotos"];
    }

    return null;
  };

  return useSWRInfinite((index) => getKey(index), photoFetcher);
};
