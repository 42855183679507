import styled from "styled-components";

const A = styled.a`
  position: relative;
  color: ${(props) => props.theme.text};
  text-decoration: none;
  font-family: "Open Sans";
  line-height: 1.4;

  :hover, &.active {
    color: ${(props) => props.theme.primary};
    cursor: pointer;
  }

  :after {
    content: " ";
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: .125em;
    -webkit-transition: all .3s cubic-bezier(.86,0,.07,1);
    transition: all .3s cubic-bezier(.86,0,.07,1);
    background-color: ${(props) => props.theme.primary};
  }

  &:hover:after, &.active:after {
    width: 100%;
  }
`;

export default A;
