import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { NavLink as RouterNavLink } from "react-router-dom";

const SideMenuA = styled.a`
  display: block;
  text-decoration: none;
  font-family: Montserrat;
  padding: 20px 20px 20px 5vw;
  color: ${(props) => props.theme.menuText};

  :hover {
    background-color: ${(props) => props.theme.menuBackgroundHover};
  }

  &.active {
    background-color: ${(props) => props.theme.primary};
  }
`;

const NavLink = (props) => (
  <SideMenuA as={RouterNavLink} {...props}>{props.children}</SideMenuA>
);

NavLink.defaultProps = {
  children: null,
};

NavLink.propTypes = {
  children: PropTypes.node,
};

export default NavLink;
