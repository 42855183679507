import React  from "react";

import UserInfo from "components/UserInfo";
import { useUserContext } from "contexts";

export default function UserInfoContainer() {
  const { user } = useUserContext();

  if (user === undefined) {
    return null;
  }

  return (<UserInfo name={user.display_name} avatar={user.avatar_full_url} />);
}
