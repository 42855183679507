import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export const CreditsWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  cursor: default;
  color: ${(props) => props.theme.headerText};
`;

export const CreditsTotal = styled.div`
  min-width: 40px;
  border-radius: 100px;
  padding: 0.5em 0.8em;
  font-size: 12px;
  font-weight: 700;
  margin-right: 0.5em;
  text-align: center;
  background: ${(props) => (props.color)};
`;

const Credits = ({ total, ...props }) => {
  const { t } = useTranslation();
  const color = total >= 0 ? props.colors.positive : props.colors.negative;

  return (
    <CreditsWrapper {...props}>
      <CreditsTotal color={color}>{total}</CreditsTotal>
      {t("components.header.credits")}
    </CreditsWrapper>
  );
};

Credits.defaultProps = {
  colors: {
    positive : "#00aa00",
    negative : "#cc0000",
  },
};

Credits.propTypes = {
  total  : PropTypes.number.isRequired,
  colors : PropTypes.object,
};

export default Credits;
