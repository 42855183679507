import useSWR from "swr";

import { getCurrentUser } from "utils/api";

/**
 * Wrapper around useSWR(getCurrentUser) to handle logout
 * on error.
 *
 * @param {string} accessToken Token to identify the user
 * @param {Function} logout Callback to call in case of error
 * @returns {array} Response from useSWR
 */
export const useCurrentUser = (accessToken, logout) => useSWR(
  () => (accessToken ? `getCurrentUser?${accessToken}` : null),
  () => getCurrentUser().catch(logout),
  {
    suspense        : true,
    refreshInterval : 0,
    errorRetryCount : 1,
  },
);

export default useCurrentUser;
