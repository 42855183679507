import styled from "styled-components";

const H2 = styled.h2`
  font-size: 1.5em;
  font-family: "Open Sans";
  font-weight: 300;
  line-height: 1.4;
`;

export default H2;
