import styled from "styled-components";

const Input = styled.input`
  background: #efefef;
  color: ${(props) => props.theme.text};
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 1.4;
  outline: none;

  padding: 0.5em;
  margin: 0.5em 0.5em 0.5em 0;
  border-width: 0;
  border-top: 3px solid ${(props) => props.theme.text};
  width: 100%;
`;

export default Input;
