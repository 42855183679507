import styled from "styled-components";

const P = styled.p`
  color: ${(props) => props.theme.text};
  font-family: "Open Sans";
  line-height: 1.4;

  margin: 0.5em 0;
`;

export default P;
