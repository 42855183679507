import axios from "utils/axios";

const SSO_URL = process.env.REACT_APP_SSO_URL;
const API_URL = process.env.REACT_APP_API_URL;

export const apiResponse = (response) => response?.data;

export async function getToken(email, password) {
  const clientId     = process.env.REACT_APP_SSO_ID;
  const clientSecret = process.env.REACT_APP_SSO_SECRET;
  const scope        = "basic";
  const grantType    = "password";
  const url          = `${SSO_URL}/token`;

  const ssoResponse = await axios.post(url, {
    username      : email,
    client_id     : clientId,
    client_secret : clientSecret,
    grant_type    : grantType,
    password,
    scope,
  });

  return apiResponse(ssoResponse);
}

/**
 * @typedef {Object} User
 * @property {string} username - username of user
 * @property {string} first_name - first name of user
 * @property {string} last_name - last name of user
 * @property {string} phone - phone of user
 * @property {string} business_website - business website of user
 * @property {string} avatar - avatar of user
 * @property {string} avatar_full_url - avatar full url of user
 * @property {string} display_name - display name of user
 * @property {number} credits - credits of user
 * @property {string} invoicing_method - invoicing method of user
 * @property {string} account_blocking_status - account blocking status of user
 *
 * @returns {User} Current authenticated user
 */
export const getCurrentUser = () => axios
  .get(`${API_URL}/users/`)
  .then(apiResponse);

export const getPhotos = async (pageNumber = 1, limit = 50, status = ["PREVIEW", "BOUGHT"]) => {
  const offset = pageNumber * limit - limit;

  const photosResponse = await axios
    .get(`${API_URL}/hdrs/`, {
      params: {
        limit,
        offset,
        status: status.length ? status : null,
      },
    });

  return apiResponse(photosResponse);
};

export const buyPhotos = async (ids) => {
  const buyResponse = await axios
    .post(`${API_URL}/hdrs/buy`, {
      hdr: ids,
    });

  return apiResponse(buyResponse);
};

/**
 * Delete a HDR by id
 *
 * @param {int} id
 * @returns {object} apiResponse
 */
export const deletePhoto = async (id) => {
  const deleteResponse = await axios.delete(`${API_URL}/hdrs/${id}`);

  return apiResponse(deleteResponse);
};

/**
 * Delete multiple HDR with an array of id
 *
 * @param {int[]} ids
 */
export const deletePhotos = (ids) => Promise.all(ids.map(deletePhoto));

/**
 * Use a refresh_token to get a new access_token
 *
 * @param {string} token refresh_token to use to get a new access_token
 * @returns {object} access_token info
 */
export const refreshToken = async (token) => {
  const clientId     = process.env.REACT_APP_SSO_ID;
  const clientSecret = process.env.REACT_APP_SSO_SECRET;
  const grantType    = "refresh_token";
  const url          = `${SSO_URL}/token`;

  const ssoResponse = await axios.post(url, {
    client_id     : clientId,
    client_secret : clientSecret,
    grant_type    : grantType,
    refresh_token : token,
  });

  return apiResponse(ssoResponse);
};

export const downloadPublicPhotoUrl = async (url) => {
  const response = await axios.get(url, {
    responseType : "arraybuffer",
    public       : true,
  });

  return apiResponse(response);
};
