/* eslint-disable no-underscore-dangle */
import axios from "axios";

import { refreshToken } from "utils/api";

// Create the custom instance
const axiosApiInstance = axios.create();

// ------ Request interceptors (run before request is executed) ---------//

/**
 * if there's a accessToken in localStorage, inject it in the Authorization Header.
 *
 * Add access Token to request
 * @param {object} config
 * @returns {object} config with Authorization header
 */
export const handleRequestFulfilled = (config = {}) => {
  const accessToken = localStorage.getItem("accessToken");

  // Don't add authorization headers in public api calls
  if (accessToken && config?.public !== true) {
    if (!("headers" in config)) {
      config.headers = {};
    }

    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
};

/**
 * We just forward the error.
 *
 * @param {Error} error
 * @returns {Promise}
 */
export const handleRequestError = (error) => Promise.reject(error);

axiosApiInstance.interceptors.request.use(handleRequestFulfilled, handleRequestError);
// -------------------------//

// ------ Response interceptors (run when response is received) ---------//

/**
 * If response doesn't have error, just proceed the response.
 *
 * @param {Response} response
 * @returns {Response} response
 */
export const handleResponseFulfilled = (response) => response;

/**
 * If the error is 403 (Unauthorized Request) and we haven't already retried, fetch a new token with the refresh token and retry the request.
 *
 * @param {Error} error
 */
export const handleResponseError = async (error) => {
  const originalRequest     = error.config || {};
  const currentRefreshToken = localStorage.getItem("refreshToken");

  if (!originalRequest._retry && currentRefreshToken !== null && (error.response?.status === 403 || error.message === "Network Error")) {
    originalRequest._retry = true;

    try {
      const token = await refreshToken(currentRefreshToken);

      localStorage.setItem("accessToken", token.access_token);
      localStorage.setItem("refreshToken", token.refresh_token);

      return axiosApiInstance.request(originalRequest);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  return Promise.reject(error);
};

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(handleResponseFulfilled, handleResponseError);

// -------------------------//

export default axiosApiInstance;
