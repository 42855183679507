import React from "react";
import { useTranslation } from "react-i18next";
import { useUserContext } from "contexts";
import { Redirect } from "react-router-dom";

import { LayoutNoMenu, Content } from "layouts/NoMenu";
import LoginForm from "containers/LoginForm";
import H1 from "components/H1";

function Login() {
  const { t }    = useTranslation();
  const { user } = useUserContext();

  if (typeof user === "object") {
    return <Redirect to={{ pathname: "/photos", search: "?status=PREVIEW&status=BOUGHT" }} />;
  }

  return (
    <LayoutNoMenu>
      <Content>
        <H1>{t("views.login.title")}</H1>
        <LoginForm />
      </Content>
    </LayoutNoMenu>
  );
}

export default Login;
