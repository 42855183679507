import React  from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "components/Button";

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 2em 0;
  width: 100%;
`;

export default function PhotoListLoadMore({ onClick, ...props }) {
  const { t } = useTranslation();

  return (
    <Container>
      <Button onClick={onClick} {...props} primary>{t("components.photoList.loadMore")}</Button>
    </Container>
  );
}

PhotoListLoadMore.propTypes = {
  onClick: PropTypes.func.isRequired,
};
