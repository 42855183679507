import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import SideMenuLink from "components/SideMenuLink";

const SideMenuList = styled.ul`
  display: flex;
  flex-direction: column;
`;

const SideMenuItem = styled.li`
  list-style-type: none;
`;

const SideMenu = (props) => {
  const { t } = useTranslation();

  return (
    <nav {...props}>
      <SideMenuList>
        <SideMenuItem>
          <SideMenuLink to={{ pathname: "/photos", search: "?status=PREVIEW&status=BOUGHT" }}>{t("views.photos.title")}</SideMenuLink>
        </SideMenuItem>
        <SideMenuItem>
          <SideMenuLink to="/profile">{t("views.profile.title")}</SideMenuLink>
        </SideMenuItem>
      </SideMenuList>
    </nav>
  );
};

export default SideMenu;
