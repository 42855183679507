import React, { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

export const Overlay = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
`;

export const Alert = styled.div`
  z-index: 110;
  font-family: "Open Sans", sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default function Modal({ children, ...props }) {
  useEffect(() => {
    document.documentElement.classList.add("locked");
    return () => document.documentElement.classList.remove("locked");
  });

  return (
    <>
      <Overlay {...props} />
      <Alert>
        {children}
      </Alert>
    </>
  );
}

Modal.propTypes = {
  children: PropTypes.any.isRequired,
};
