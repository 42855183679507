import React  from "react";
import { useTranslation } from "react-i18next";
import { usePhotoListSelectionContext } from "contexts";

import UnselectButton from "components/UnselectButton";

export default function UnselectButtonContainer() {
  const { t }               = useTranslation();
  const { selected, clear } = usePhotoListSelectionContext();

  return (
    <UnselectButton
      onClick={clear}
      disabled={selected === undefined || selected.length === 0}
    >
      {t("containers.unselectButton").toUpperCase()}
    </UnselectButton>
  );
}
