import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import StyledCheckbox from "components/StyledCheckBox";

export const CheckboxButton = styled.button`
  font-size: 0;
  display: inline-block;
  vertical-align: middle;
  outline: none;
  padding: 0;
  margin: 0;
  border: 0;
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox", tabIndex: "-1" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Checkbox = ({ className, selected, onSelectionChange, ...props }) => {
  const onChange = () => onSelectionChange(!selected);

  return (
    <CheckboxButton className={className} onClick={onChange} {...props}>
      <HiddenCheckbox selected={selected} />
      <StyledCheckbox selected={selected} />
    </CheckboxButton>
  );
};

Checkbox.defaultProps = {
  className : null,
  selected  : false,
};

Checkbox.propTypes = {
  onSelectionChange : PropTypes.func.isRequired,
  className         : PropTypes.string,
  selected          : PropTypes.bool,
};

export default Checkbox;
