import styled from "styled-components";

export const colors = (theme) => ({
  default: {
    color: theme.text,
  },
  primary: {
    color: theme.background,
  },
});

const ButtonIcon = styled.svg`
  margin-right: 5px;
  height: 1.2em;
  width: 1.2em;
  
  & > path {
    stroke-width: 10px;
    stroke: ${(props) => colors(props.theme)[props.color].color};
    fill: ${(props) => colors(props.theme)[props.color].color};
  }
`;

ButtonIcon.defaultProps = {
  color: "default",
};

export default ButtonIcon;
