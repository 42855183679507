export default {
  background          : "#ffffff",
  text                : "#000000",
  primary             : "#fbc130",
  headerBackground    : "#000000",
  headerText          : "#ffffff",
  menuBackground      : "#333333",
  menuBackgroundHover : "#444444",
  menuText            : "#ffffff",
  photoItemBorder     : "#f1f1f0",
  tag                 : {
    bought    : "#47ac1d",
    developed : "#aaaaaa",
  },
};
