import React from "react";
import {
  Formik, Form, Field, ErrorMessage,
} from "formik";
// import { string, object } from "yup";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Error from "components/Error";
import Input from "components/Input";
// import Button from "components/Button"
import Label from "components/Label";

const Profile = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 1000px;
  margin: 40px 0;
  border-top: 3px solid #000;
`;

const FieldGroup = styled.div`
  margin: 20px 0;

  &.avatar {
    position: absolute;
    top: 0px;
    right: 0;
    text-align: center;
  }
`;

const ProfileInput = styled(Input)`
  font-size: 15px;
  padding: 16px 20px;
  border: 0;
  background-color: #efefef;

  :disabled {
    border: 0;
    padding: 0;
    background-color: transparent;
  }
`;

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid ${(props) => props.theme.background};
`;

const AvatarImg = styled.img`
  max-height: 100%;
`;

// const profileValidation = object().shape({
//   password: string()
//     .min(8, "Too Short!")
//     .max(255, "Too Long!")
//     .required("Required"),
//   email: string()
//     .email("Invalid email")
//     .required("Required"),
// });

// export const handleSubmit = (onSubmit) => async (values, { setSubmitting, setFieldError }) => {
//   try {
//     await onSubmit(values);
//   } catch (error) {
//     setFieldError("general", "Authentification failed");
//   } finally {
//     setSubmitting(false);
//   }
// };

function ProfileForm(props) {
  // const formHandleSubmit = handleSubmit(props.onSubmit);
  const { t } = useTranslation();

  return (
    <Profile>
      <Formik
        initialValues={{
          first_name       : props.user.first_name,
          last_name        : props.user.last_name,
          phone            : props.user.phone,
          business_website : props.user.business_website,
        }}
        // validationSchema={profileValidation}
        // onSubmit={formHandleSubmit}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            <Error>{errors.general}</Error>
            <FieldGroup>
              <Label htmlFor="first_name">{t("components.profileForm.firstName")}</Label>
              <Field type="text" name="first_name" id="first_name" as={ProfileInput} data-testid="first_name" disabled />
              <ErrorMessage name="first_name" component={Error} />
            </FieldGroup>
            <FieldGroup>
              <Label htmlFor="last_name">{t("components.profileForm.lastName")}</Label>
              <Field type="text" name="last_name" id="last_name" as={ProfileInput} data-testid="last_name" disabled />
              <ErrorMessage name="last_name" component={Error} />
            </FieldGroup>
            <FieldGroup>
              <Label htmlFor="phone">{t("components.profileForm.phone")}</Label>
              <Field type="text" name="phone" id="phone" as={ProfileInput} data-testid="phone" disabled />
              <ErrorMessage name="phone" component={Error} />
            </FieldGroup>
            <FieldGroup>
              <Label htmlFor="business_website">{t("components.profileForm.website")}</Label>
              <Field type="text" name="business_website" id="business_website" as={ProfileInput} data-testid="business_website" disabled />
              <ErrorMessage name="business_website" component={Error} />
            </FieldGroup>
            <FieldGroup className="avatar">
              <Avatar>
                <AvatarImg src={props.user.avatar_full_url} />
              </Avatar>
              <ErrorMessage name="avatar" component={Error} />
            </FieldGroup>
          </Form>
        )}
      </Formik>
    </Profile>
  );
}

ProfileForm.propTypes = {
  // onSubmit : PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default ProfileForm;
