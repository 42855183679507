/* istanbul ignore file */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// It's used to detect the language of the user
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const DEBUG = process.env.NODE_ENV !== "production";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng : "en",
    debug       : DEBUG,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
