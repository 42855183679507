import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import A from "components/A";
import { useUserContext } from "contexts";

export const Link = styled(A)`
  font-family: Montserrat, sans-serif;
  color: #888888;
  font-size: 13px;
  line-height: 1;
`;

export default function Logout() {
  const { t }      = useTranslation();
  const { logout } = useUserContext();

  return (<Link onClick={logout}>{t("components.header.logout")}</Link>);
}
