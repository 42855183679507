import styled from "styled-components";

import P from "components/P";

const Error = styled(P)`
  color: red;
  font-size: 14px;
`;

export default Error;
