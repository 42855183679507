import JSZip from "jszip";
import { saveAs } from "file-saver";

import { downloadPublicPhotoUrl } from "utils/api";

export default async function downloadZip(photos) {
  const zip = new JSZip();

  photos.forEach((photo) => {
    zip.file(`exposio_${photo.id}.jpg`, downloadPublicPhotoUrl(photo.url_original));
  });

  const content = await zip.generateAsync({ type: "blob" });

  const now           = new Date();
  const formattedDate = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}_${now.getHours()}-${now.getMinutes()}`;

  saveAs(content, `exposio_photos_${formattedDate}.zip`);

  return zip;
}
