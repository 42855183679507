const debounce = (callback, delay) => {
  let timer = null;

  return () => {
    // Last event event was less than delay ago, restart a new timer
    if (timer !== null) {
      clearTimeout(timer);
    }

    // Debounce to only trigger callback delay after no event was received
    timer = setTimeout(callback, delay);
  };
};

export default debounce;
