import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import { usePhotos, useQuery } from "containers/PhotoListProvider/hooks";
import { PhotoListContext } from "contexts";

const PAGE_SIZE = 60;

export default function PhotoListProvider({ children }) {
  const query = useQuery();

  const { data: pages, error, size, setSize } = usePhotos(PAGE_SIZE, query.getAll("status"));

  const isLoadingInitialData = !pages && !error;
  const isLoadingMore        = isLoadingInitialData || (size > 0 && pages && typeof pages[size - 1] === "undefined");

  const nextPage = useCallback(
    () => {
      if (isLoadingMore === false) {
        setSize(size + 1);
      }
    },
    [isLoadingMore, setSize, size],
  );

  const clearPhotosCache = useCallback(async () => {
    await setSize(0);
    await setSize(1);
  }, [setSize]);

  const photoListState = useMemo(() => ({
    pages     : typeof pages !== "undefined" ? pages : [],
    nbPerPage : PAGE_SIZE,
    clearPhotosCache,
    error,
    size,
    setSize,
    isLoadingMore,
    nextPage,
  }), [pages, error, size, setSize, isLoadingMore, nextPage, clearPhotosCache]);

  return (
    <PhotoListContext.Provider value={photoListState}>
      {children}
    </PhotoListContext.Provider>
  );
}

PhotoListProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
