import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import theme from "themes/exposio";
import Filter from "components/Filter";

const Row = styled.div`
  display: flex;
  margin-left: -5px;
  margin-right: -5px;
`;

export default function PhotoListFilters({ updateFilters, selected }) {
  const { t } = useTranslation();

  const checkboxList = [
    {
      value : "PREVIEW",
      text  : t("components.photoItem.status.developed"),
      color : theme.tag.developed,
    },
    {
      value : "BOUGHT",
      text  : t("components.photoItem.status.bought"),
      color : theme.tag.bought,
    },
  ];

  const onChange = (value, isSelected) => {
    if (isSelected) {
      return updateFilters([...selected, value]);
    }

    return updateFilters(selected.filter((item) => item !== value));
  };

  const Checkboxes = checkboxList.map((checkboxConfig) => {
    const checkboxSelected = selected.indexOf(checkboxConfig.value) !== -1;

    return (
      <Filter
        key={checkboxConfig.value}
        value={checkboxConfig.value}
        color={checkboxConfig.color}
        selected={checkboxSelected}
        onFilterChange={onChange}
      >
        {checkboxConfig.text}
      </Filter>
    );
  });

  return (
    <Row>{Checkboxes}</Row>
  );
}

PhotoListFilters.defaultProps = {
  selected: [],
};

PhotoListFilters.propTypes = {
  selected      : PropTypes.array,
  updateFilters : PropTypes.func.isRequired,
};
