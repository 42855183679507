import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import LoadingItem from "components/LoadingItem";
import PhotoItem from "components/PhotoItem";

export const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0 -8px;
  width: 100%;
`;

export default function PhotoList({ pages, isLoadingMore, nbPerPage, updateSelected, isSelected }) {
  const renderPhotoItem = (photo, photoIndex, pageIndex) => {
    const id = `page-${pageIndex + 1}-${photoIndex}`;

    const onSelectionChange = () => updateSelected(photo.id);

    return <PhotoItem id={id} key={photo.id} photo={photo} selected={isSelected(photo.id)} onSelectionChange={onSelectionChange} />;
  };

  const Photos = pages.map((photos, pageIndex) => photos.map((photo, photoIndex) => renderPhotoItem(photo, photoIndex, pageIndex)));

  const renderLoadingItems = () => [...new Array(nbPerPage).keys()].map((key) => <LoadingItem key={key} />);

  return (
    <List id="photolist">
      {Photos}
      {isLoadingMore && renderLoadingItems()}
    </List>
  );
}

PhotoList.propTypes = {
  pages          : PropTypes.array.isRequired,
  nbPerPage      : PropTypes.number.isRequired,
  isLoadingMore  : PropTypes.bool.isRequired,
  updateSelected : PropTypes.func.isRequired,
  isSelected     : PropTypes.func.isRequired,
};
