import styled from "styled-components";

import A from "components/A";

const NavLink = styled(A)`
  font-family: Montserrat, sans-serif;
  color: ${(props) => props.theme.headerText};
`;

export default NavLink;
