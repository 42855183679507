import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import A from "components/A";
import Logout from "containers/Logout";

const Avatar = styled.img`
  border-radius: 50%;
  margin-right: 10px;
  height: 34px;
  width: 34px;
  vertical-align: middle;
`;

const NavA = styled(A)`
  font-family: Montserrat,sans-serif;
  color: ${(props) => props.theme.primary};
  text-decoration: none;
  line-height: 1;
  :hover {
    color: ${(props) => props.theme.primary};
  }
  &.active:after {
    width: 0;
  }
  &.active:hover:after {
    width: 100%;
  }
`;

const Name = styled.span`
  color: ${(props) => props.theme.headerText};
  font-size: 14px;
`;

const Row = styled.div`
  display: flex;
  margin-left: 40px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Span = styled.span`
  line-height: 1;
  margin-top: 2px;
`;

function UserInfo({ name, avatar, ...props }) {
  return (
    <Row {...props}>
      <NavLink to="/profile">
        <Avatar data-testid="avatar" src={avatar} />
      </NavLink>
      <Column>
        <NavA as={NavLink} to="/profile">
          <Name data-testid="name">{name}</Name>
        </NavA>
        <Span><Logout /></Span>
      </Column>
    </Row>
  );
}

UserInfo.propTypes = {
  name   : PropTypes.string.isRequired,
  avatar : PropTypes.string.isRequired,
};

export default UserInfo;
