import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { createPortal } from "react-dom";

import Modal from "components/Modal";
import H2 from "components/H2";
import P from "components/P";
import Spinner from "components/Spinner";
import Button from "components/Button";
import { Buttons } from "components/ConfirmAlert/ConfirmAlert";
import ConfirmAlert from "components/ConfirmAlert";
import { useTranslation } from "react-i18next";

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ConfirmButton = ({
  nbSelected,
  disabled,
  translationNamespace,
  onConfirm,
  translations,
  ...props
}) => {
  const states = {
    CONFIRMING : "CONFIRMING",
    PROCESSING : "PROCESSING",
    SUCCESS    : "SUCCESS",
    ERROR      : "ERROR",
  };

  const [state, setState] = useState();
  const { t }             = useTranslation();

  const open  = () => setState(states.CONFIRMING);
  const close = () => setState();

  const startConfirmProcess = async () => {
    setState(states.PROCESSING);

    try {
      await onConfirm();

      setState(states.SUCCESS);
    } catch (e) {
      setState(states.ERROR);
    }
  };

  const translate = (key) => {
    if (key in translations) {
      return translations[key];
    }

    return t(key, { count: nbSelected });
  };

  const isDisabled = disabled || nbSelected === 0;

  return (
    <>
      <Button onClick={open} disabled={isDisabled} {...props}>{props.children}</Button>
      {state === states.CONFIRMING && createPortal(
        <ConfirmAlert
          title={translate(`${translationNamespace}.confirm.title`)}
          message={translate(`${translationNamespace}.confirm.message`)}
          confirmButtonText={translate(`${translationNamespace}.action`)}
          cancelButtonText={translate("components.confirmButton.cancel")}
          onConfirm={startConfirmProcess}
          onCancel={close}
          data-testid="confirmingModal"
        />, document.body,
      )}

      {state === states.PROCESSING && createPortal(
        <Modal data-testid="processingModal">
          <Row>
            <H2>{translate(`${translationNamespace}.processing.title`)}</H2>
            <Spinner />
          </Row>
        </Modal>, document.body,
      )}

      {state === states.SUCCESS && createPortal(
        <Modal data-testid="successModal" onClick={close}>
          <H2>{translate(`${translationNamespace}.success.title`)}</H2>
          <P>{translate(`${translationNamespace}.success.message`)}</P>
          <Buttons>
            <Button onClick={close} color="primary">
              {translate("components.confirmButton.close")}
            </Button>
          </Buttons>
        </Modal>, document.body,
      )}

      {state === states.ERROR && createPortal(
        <Modal data-testid="errorModal" onClick={close}>
          <H2>{translate(`${translationNamespace}.error.title`)}</H2>
          <P>{translate(`${translationNamespace}.error.message`)}</P>
          <Buttons>
            <Button onClick={close} color="primary">
              {translate("components.confirmButton.close")}
            </Button>
          </Buttons>
        </Modal>, document.body,
      )}
    </>
  );
};

ConfirmButton.propTypes = {
  children             : PropTypes.any.isRequired,
  translationNamespace : PropTypes.string.isRequired,
  onConfirm            : PropTypes.func.isRequired,
  nbSelected           : PropTypes.number.isRequired,
  disabled             : PropTypes.bool,
  translations         : PropTypes.object,
};

ConfirmButton.defaultProps = {
  disabled     : false,
  translations : {},
};

export default ConfirmButton;
