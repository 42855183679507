import styled from "styled-components";

const UnselectButton = styled.button`
  position: relative;
  border: none;
  padding: 0 0.8em;
  width: auto;
  background: transparent;
  font: inherit;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  color: ${(props) => props.theme.text};
  text-decoration: none;
  line-height: 1.4;
  font-family: "Open Sans", sans-serif;
  font-weight: 900;
  font-size: 12px;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  :hover, &.active {
    color: ${(props) => props.theme.primary};
    cursor: pointer;
  }

  :focus {
    outline: none;
  }

  &[disabled] {
    opacity: 0.3;
    cursor: default;
    :after {
      width: 0;
    }
    :hover {
      color: ${(props) => props.theme.text};
      :after {
        width: 0;
      }
    }
  }

  :after {
    background-color: ${(props) => props.theme.text};
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0.8em;
    height: 3px;
    -webkit-transition: width .3s cubic-bezier(.86,0,.07,1);
    transition: width .3s cubic-bezier(.86,0,.07,1);
    width: calc(100% - 1.6em);
  }

  &:hover:after, &.active:after {
    background-color: ${(props) => props.theme.primary};
  }
`;

export default UnselectButton;
