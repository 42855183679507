import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { string, object, setLocale } from "yup";
import { useTranslation } from "react-i18next";

import Error from "components/Error";
import Input from "components/Input";
import Button from "components/Button";
import Label from "components/Label";

const Login = styled.div`
  width: 250px;

  @media (min-width: 480px) {
    width: 400px;
  }
`;

const FieldGroup = styled.div`
  margin: 20px 0;
`;

const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

setLocale({
  mixed: {
    default  : { key: "login.validation.field_invalid" },
    required : { key: "components.loginForm.validation.required" },
  },
  string: {
    email: { key: "components.loginForm.validation.invalidEmail" },
  },
});

const loginValidation = object().shape({
  password: string()
    .required(),
  email: string()
    .email()
    .required(),
});

export const handleSubmit = (onLogin, t) => async (values, { setSubmitting, setFieldError }) => {
  try {
    await onLogin(values);
  } catch (error) {
    setFieldError("general", t("components.loginForm.error.authentificationFailed"));
    setSubmitting(false);
  }
};

function LoginForm(props) {
  const { t }            = useTranslation();
  const formHandleSubmit = handleSubmit(props.onLogin, t);

  const ErrorI18N = (testId) => ({ key, values }) => (
    <Error data-testid={testId}>{t(key, values)}</Error>
  );

  return (
    <Login>
      <Formik
        initialValues={{ email: props.email, password: props.password }}
        validationSchema={loginValidation}
        onSubmit={formHandleSubmit}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            <Error>{errors.general}</Error>
            <FieldGroup>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              <Label htmlFor="email">{t("components.loginForm.username")}</Label>
              <Field type="email" name="email" id="email" as={Input} data-testid="email" />
              <ErrorMessage name="email" render={ErrorI18N("error-email")} />
            </FieldGroup>
            <FieldGroup>
              <Label htmlFor="password">{t("components.loginForm.password")}</Label>
              <Field type="password" name="password" id="password" as={Input} data-testid="password" />
              <ErrorMessage name="password" render={ErrorI18N("error-password")} />
            </FieldGroup>
            <FormFooter>
              <Button type="submit" disabled={isSubmitting} color="primary" data-testid="login-button">
                {t("components.loginForm.submit")}
              </Button>
            </FormFooter>
          </Form>
        )}
      </Formik>
    </Login>
  );
}

LoginForm.propTypes = {
  onLogin  : PropTypes.func.isRequired,
  email    : PropTypes.string,
  password : PropTypes.string,
};

LoginForm.defaultProps = {
  email    : "",
  password : "",
};

export default LoginForm;
