import styled from "styled-components";

const Label = styled.label`
  color: ${(props) => props.theme.text};
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
`;

export default Label;
