import styled from "styled-components";

export const LayoutNoMenu = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-height: 100%;
`;

export const Content = styled.div`
  display: flex;
  margin: auto;
  width: min-content;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
