import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import Logo from "containers/Logo";
import Navbar from "containers/Navbar/Navbar";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  height: 60px;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 5%;
  padding-left: 5%;
  align-items: center;
  background: ${(props) => props.theme.headerBackground};
`;

const Header = (props) => (
  <HeaderWrapper {...props}>
    <Logo />
    <Navbar />
    {props.children}
  </HeaderWrapper>
);

Header.defaultProps = {
  children: null,
};

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
